/* eslint-disable import/no-unresolved */
<template>
  <section id="events">
    <p>Gerenciamento de Eventos</p>
    <div>
      <add-new-event
        :is-add-new-event-sidebar-active.sync="isAddNewEventSidebarActive"
        @onSaved="onSaved($event)"
        @close="isAddNewEventSidebarActive = false"
      />

      <edit-event
        :is-edit-event-sidebar-active.sync="isEditEventSidebarActive"
        :data="rowData"
        @onSaved="onSaved($event)"
        @close="isEditEventSidebarActive = false"
      />

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrar</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>eventos</label>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="9"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchValue"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
                />
                <b-button
                  variant="primary"
                  @click="isAddNewEventSidebarActive = true"
                >
                  <span class="text-nowrap">Adicionar evento</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-table
            id="mytable"
            ref="mytable"
            :items="items"
            :fields="tableColumnsCongress"
            :filter="searchValue"
            primary-key="id"
            :total-rows="rows"
            :per-page="perPage"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :current-page="currentPage"
            show-empty
            empty-text="Nenhuma informação encontrada"
            busy.sync="isBusy"
            empty-filtered-text="Nenhum registro filtrado"
          >
            <template slot="empty">
              <div
                v-if="isBusy"
                class="text-center"
              >
                <b-spinner class="align-middle" />
              </div>
              <div
                v-else
                class="text-center"
              >
                Nenhuma informação encontrada
              </div>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item :href="`/administrador/${$route.params.congress_slug}/evento/sub-atividades/${data.item.id}`">
                  <feather-icon
                    icon="ListIcon"
                  />
                  <span class="align-middle ml-50">
                    Sub-atividades
                  </span>
                </b-dropdown-item>

                <!-- <b-dropdown-item @click="openSpeaker(data.item.id)">
                  <feather-icon icon="UserPlusIcon" />
                  <span class="align-middle ml-50">Palestrante</span>
                </b-dropdown-item> -->

                <b-dropdown-item @click="openCordinator(data.item.id)">
                  <feather-icon icon="KeyIcon" />
                  <span class="align-middle ml-50">Coordenador</span>
                </b-dropdown-item>

                <b-dropdown-item @click="openDebater(data.item.id)">
                  <feather-icon icon="MicIcon" />
                  <span class="align-middle ml-50">Discutidor</span>
                </b-dropdown-item>

                <b-dropdown-item @click="edit(data.item)">
                  <feather-icon
                    icon="EditIcon"
                  />
                  <span
                    class="align-middle ml-50"
                  >Editar</span>
                </b-dropdown-item>

                <b-dropdown-item @click="remove(data.item)">
                  <feather-icon
                    icon="TrashIcon"
                  />
                  <span
                    class="align-middle ml-50"
                  >Deletar</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <!-- <span class="text-muted">A partir de {{ dataMeta.from }} para {{ dataMeta.to }} de {{ dataMeta.of }} palestrantes</span> -->
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </div>
    <!-- Modal debater -->
    <b-modal
      id="bv-modal-debater"
      hide-footer
      title="Adicionar discutidor"
    >
      <form role="form">
        <div class="form-group">
          <label for="speaker">Lista de discutidor</label>
          <div>
            <div>
              <div
                v-for="(item, index) in selectedDebater"
                :key="index"
              >
                <div style="margin: 5px;">
                  {{ item.name_badge }}
                  <feather-icon
                    icon="Trash2Icon"
                    style="cursor:pointer;color:red;"
                    @click="deleteDebater(item.id)"
                  />
                </div>
              </div>
              <br>
              <label
                class="typo__label"
              >Selecione o discutidor</label>

              <multiselect
                v-model="selectEventBlockspeakers"
                :options="speakers"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :max="7"
                :preserve-search="true"
                placeholder="Selecione"
                label="name_badge"
                track-by="name_badge"
                :preselect-first="false"
              >
                <template
                  slot="selection"
                  slot-scope="{ values, isOpen }"
                >
                  <span
                    v-if="values.length &amp;&amp; !isOpen"
                    class="multiselect__single"
                  >{{ values.length }} options selected</span>
                </template>
              </multiselect>
            </div>
          </div>
          <div style="margin-top: 30px;">
            <button
              class="btn btn-success btn-sm float-left m-t-n-xs"
              type="submit"
              @click.prevent="createDebater()"
            >
              Salvar alteração
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- Cordinator -->
    <div class="row">
      <div class="col-lg-12">
        <b-modal
          id="bv-modal-cordinator"
          hide-footer
          title="Adicionar discutidor"
        >
          <form role="form">
            <div class="form-group">
              <label for="speaker">Lista de coordenador</label>
              <div>
                <div>
                  <div
                    v-for="(item, index) in selectedCordinator"
                    :key="index"
                  >
                    <div style="margin: 5px;">
                      {{ item.name_badge }}
                      <feather-icon
                        icon="Trash2Icon"
                        style="cursor:pointer;color:red;"
                        @click="deleteCordinator(item.id)"
                      />
                    </div>
                  </div>
                  <br>
                  <label
                    class="typo__label"
                  >Selecione o coordenador</label>
                  <multiselect
                    v-model="selectEventBlockspeakers"
                    :options="speakers"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :max="7"
                    :preserve-search="true"
                    placeholder="Selecione"
                    label="name_badge"
                    track-by="name_badge"
                    :preselect-first="false"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, isOpen }"
                    >
                      <span
                        v-if="values.length &amp;&amp; !isOpen"
                        class="multiselect__single"
                      >{{ values.length }} options selected</span>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div style="margin-top: 30px;">
                <button
                  class="btn btn-success btn-sm float-left m-t-n-xs"
                  type="submit"
                  @click.prevent="createCordinator()"
                >
                  Salvar alteração
                </button>
              </div>
            </div>
          </form>
        </b-modal>
      </div>
    </div>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import AddNewEvent from './AddNewEvent.vue'
import EditEvent from './EditEvent.vue'
import EventService from './eventsService'
import Multiselect from 'vue-multiselect'

export default {

  components: {
    AddNewEvent,
    EditEvent,
    // EditSpeaker,

    Multiselect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    // BBadge,
    // BDropdown,
    // BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const isAddNewEventSidebarActive = ref(false)
    const isEditEventSidebarActive = ref(false)
    return {
      isAddNewEventSidebarActive,
      isEditEventSidebarActive,
    }
  },
  data() {
    return {
      items: [],
      speakers: [],
      selectedDebater: [],
      selectedCordinator: [],
      selectedSpeaker: [],
      eventSpeaker: [],
      selectEventBlockspeakers: [],
      formBlockSpeakers: {
        event_id: '',
        speakers: [],
      },
      tableColumnsCongress: [
        {
          key: 'id',
          label: 'Código',
          sortable: true
        },
        {
          key: 'title',
          label: 'Título',
          sortable: true
        },
        {
          key: 'description',
          label: 'Descrição',
          sortable: false,
        },
        {
          key: 'dateBr',
          label: 'Data',
          sortable: true,
        },
        {
          key: 'start_time',
          label: 'Hora Início',
          sortable: true,
        //   formatter(v, k, item) {
        //     return this.$moment(v).format('HH:mm')
        //   }
        },
        {
          key: 'end_time',
          label: 'Hora Fim',
          sortable: true,
        //   formatter(v, k, item) {
        //     return this.$moment(v).format('HH:mm')
        //   }
        },
        {
          key: 'actions',
          label: 'Ações',
          sortable: false,
        }
      ],
      rows: 0,
      perPage: 10,
      currentPage: 1,
      nextPage: this.currentPage + 1,
      prevPage: this.currentPage - 1,
      sortBy: 'id',
      sortDesc: true,
      events: [],
      perPageOptions: [10, 25, 50, 100, 200, 300, 400, 500],
      selectValue: '',
      searchValue: '',
      isBusy: false,
      isRemoving: false,
      rowData: {}
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.listEventsByCongressSlug(this.$route.params.congress_slug)
  },
  beforeMount() {
    this.listSpeakers()
  },
  methods: {
    onSaved(saved) {
      this.listEventsByCongressSlug(this.$route.params.congress_slug)
    },
    listSpeakers() {
      const params = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      }
      this.items = []
      this.isBusy = true
      EventService().fetchSpeaker(params).then(data => {
        data.response.speakers.forEach(speaker => {
          this.speakers.push(speaker)
        })
        this.rows = data.response.speakers.length
      }).catch(err => console.log(err))
        .finally(() => { this.isBusy = false })
    },
    listEventsByCongressSlug(congress_slug) {
      const params = {
        perPage: this.perPage,
        currentPage: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        congress_slug
      }
      this.items = []
      this.isBusy = true
      EventService().fetchEventsByCongressSlug(params).then(data => {
        this.items = data.response.events
        if (data.response.events.length > 0) {
          this.rows = data.response.events.length
        } else {
          this.rows = 0
        }
      }).catch(err => console.log(err))
        .finally(() => { this.isBusy = false })
    },
    edit(data) {
      this.rowData.id = data.id
      this.rowData.title = data.title
      this.rowData.description = data.description
      this.rowData.theme = data.theme
      this.rowData.type = data.type
      this.rowData.date = this.$moment(data.date).format()
      this.rowData.start_time = data.start_time
      this.rowData.end_time = data.end_time
      this.rowData.congress = data.congress_id
      this.rowData.active = data.active
      if (data.live.length > 0) {
        console.log(data)
        if (data.live[0].link || data.live[0].type_link || data.live[0].room) {
          this.rowData.link = data.live[0].link
          this.rowData.type_link = data.live[0].type_link
          this.rowData.room = data.live[0].room
          this.rowData.ready = data.live[0].ready
        }
      }
      this.isEditEventSidebarActive = true
    },
    remove(data) {
      this.$swal({
        title: 'Deletar evento?',
        text: 'Você não será capaz de reverte essa ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim, desejo deletar!',
        showLoaderOnConfirm: true,
        preConfirm: () => EventService().deleteEvent(data.id).then(response => {
          if (response.status === 200) {
            const new_items = this.items.filter((v) => v.id !== data.id)
            this.items = new_items
          }
        }).catch(err => {
          this.$swal.fire(
            'Deletado!',
            'Erro ao tentar deletar.',
            'error'
          )
        })
          .finally(() => {
            this.isBusy = false
          }),
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
            'Deletado!',
            'Evento deletado com sucesso.',
            'success'
          )
        }
      })
    },
    async openSpeaker(id, e) {
      this.selectedSpeaker = []
      EventService().getSpeakerBlock({ events_blocks_id: id }).then(api => {
        api.forEach((speaker) => {
          this.selectedSpeaker.push(speaker.speaker)
        })
      })
      this.formBlockSpeakers.events_blocks_id = id
      this.$bvModal.show('bv-modal-palestrante')
    },
    async updateSpeaker() {
      this.formSpeakers = []

      await this.selectEventBlockspeakers.forEach((item) => {
        this.formSpeakers.push(item.id)
      })

      this.formBlockSpeakers.speakers = this.formSpeakers

      try {
        EventService().updateSpeaker(this.formBlockSpeakers).then(api => {
          if (api.data.status === 400) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: api.data.message,
                variant: 'danger',
              }
            })
          } else if (api.data.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: api.data.message,
                icon: 'InfoIcon',
                variant: 'success',
              }
            })
          }

          this.$bvModal.hide('bv-modal-palestrante')
          this.selectEventBlockspeakers = []
        }).catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'InfoIcon',
              title: err.message,
              variant: 'danger',
            }
          })
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'InfoIcon',
            title: err.message,
            variant: 'danger',
          }
        })
      }
    },
    async openDebater(id, e) {
      this.selectedDebater = []
      await EventService().getSpeakers({ id }).then(res => {
        console.log(res)
        this.eventSpeaker = res
      })
      EventService().getDebater({ event_id: id }).then(api => {
        api.forEach((debater) => {
          this.selectedDebater.push(debater.speaker)
        })
      })

      this.selectEventBlockspeakers = []

      this.formBlockSpeakers.event_id = id
      this.$bvModal.show('bv-modal-debater')
    },
    async createDebater(id) {
      this.formSpeakers = []
      await this.selectEventBlockspeakers.forEach((item) => {
        this.formSpeakers.push(item.id)
      })
      this.formBlockSpeakers.speakers = this.formSpeakers

      await EventService().createDebater(this.formBlockSpeakers).then(api => {
        if (api.data.status === 400) {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'InfoIcon',
              title: api.data.message,
              variant: 'danger',
            }
          })
        } else if (api.data.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: api.data.message,
              icon: 'InfoIcon',
              variant: 'success',
            }
          })
        }
      })

      EventService().getDebater({ event_id: this.formBlockSpeakers.event_id }).then(api => {
        this.selectedDebater = []
        api.forEach((debater) => {
          this.selectedDebater.push(debater.speaker)
        })
      })
    },
    async deleteDebater(speaker_id) {
      await EventService().deleteDebater({
        speaker_id,
        event_id: this.formBlockSpeakers.event_id
      }).then(api => {
        if (api.data.status === 400) {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'InfoIcon',
              title: api.data.message,
              variant: 'danger',
            }
          })
        } else if (api.data.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: api.data.message,
              icon: 'InfoIcon',
              variant: 'success',
            }
          })
        }
      })

      EventService().getDebater({ event_id: this.formBlockSpeakers.event_id }).then(api => {
        this.selectedDebater = []
        api.forEach((debater) => {
          this.selectedDebater.push(debater.speaker)
        })
      })
    },
    async openCordinator(id, e) {
      this.selectedCordinator = []
      await EventService().getSpeakers({ id }).then(res => {
        console.log(res)
        this.eventSpeaker = res
      })
      EventService().getCordinator({ event_id: id }).then(api => {
        api.forEach((cordinator) => {
          this.selectedCordinator.push(cordinator.speaker)
        })
      })

      this.selectEventBlockspeakers = []

      this.formBlockSpeakers.event_id = id
      this.$bvModal.show('bv-modal-cordinator')
    },
    async createCordinator(id) {
      this.formSpeakers = []
      await this.selectEventBlockspeakers.forEach((item) => {
        this.formSpeakers.push(item.id)
      })
      this.formBlockSpeakers.speakers = this.formSpeakers

      await EventService().createCordinator(this.formBlockSpeakers).then(api => {
        if (api.data.status === 400) {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'InfoIcon',
              title: api.data.message,
              variant: 'danger',
            }
          })
        } else if (api.data.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: api.data.message,
              icon: 'InfoIcon',
              variant: 'success',
            }
          })
        }
      })

      EventService().getCordinator({ event_id: this.formBlockSpeakers.event_id }).then(api => {
        this.selectedCordinator = []
        api.forEach((cordinator) => {
          this.selectedCordinator.push(cordinator.speaker)
        })
      })
    },
    async deleteCordinator(speaker_id) {
      await EventService().deleteCordinator({
        speaker_id,
        event_id: this.formBlockSpeakers.event_id
      }).then(api => {
        if (api.data.status === 400) {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'InfoIcon',
              title: api.data.message,
              variant: 'danger',
            }
          })
        } else if (api.data.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: api.data.message,
              icon: 'InfoIcon',
              variant: 'success',
            }
          })
        }
      })

      EventService().getCordinator({ event_id: this.formBlockSpeakers.event_id }).then(api => {
        this.selectedCordinator = []
        api.forEach((cordinator) => {
          this.selectedCordinator.push(cordinator.speaker)
        })
      })
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
</style>
