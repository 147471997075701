import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import axios from '@axios'

export default function eventsService() {
  const fetchEventsByCongressSlug = async (queryParams) => {
    const res = await axios.get('/events/list', { params: queryParams })
    console.log(res)
    return res.data
  }

  const sendEvent = async (form) => {
    const res = await axios.post('/events/create', form)

    return res.data
  }

  const updateEvent = async (form) => {
    const res = await axios.post('/events/update', form)

    return res.data
  }

  const deleteEvent = async (id) => {
    const res = await axios.post('events/delete', { id })

    return res.data
  }

  const getSpeakers = async (queryParams) => {
    const res = await axios.get('/event/speakers', {
      params: queryParams,
    })
    return res.data.response.speakers
  }

  const getDebater = async (queryParams) => {
    const res = await axios.post('/events/debaters', queryParams)
    return res.data.response.debaters
  }

  const createDebater = async (queryParams) => {
    const res = await axios.post('/events/debaters/create', queryParams)
    return res
  }

  const deleteDebater = async (queryParams) => {
    const res = await axios.post('/events/debaters/delete', queryParams)
    return res
  }

  const getCordinator = async (queryParams) => {
    const res = await axios.post('/events/cordinators', queryParams)
    return res.data.response.cordinators
  }

  const createCordinator = async (queryParams) => {
    const res = await axios.post('/events/cordinators/create', queryParams)
    return res
  }

  const deleteCordinator = async (queryParams) => {
    const res = await axios.post('/events/cordinators/delete', queryParams)
    return res
  }

  const getSpeakerBlock = async (queryParams) => {
    const res = await axios.post('/events/speaker/block', queryParams)
    return res.data.response.speakers
  }

  const updateSpeaker = async (queryParams) => {
    const res = await axios.post('/events/blocks/speakers/create', queryParams)
    return res
  }

  const fetchSpeaker = async (queryParams) => {
    const res = await axios.get('/speakers', { params: queryParams })

    return res.data
  }

  return {
    fetchEventsByCongressSlug,
    sendEvent,
    updateEvent,
    deleteEvent,
    getSpeakers,
    getDebater,
    createDebater,
    deleteDebater,
    getCordinator,
    createCordinator,
    deleteCordinator,
    getSpeakerBlock,
    updateSpeaker,
    fetchSpeaker,
  }
}
